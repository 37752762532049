@import "../../../styles/helpers";

.backgroundWhite {
    right: 8px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    font-size: 16px;
    font-weight: 600;
    color: #1A1D1F;
    &:hover {
        background: #FFFFFF; } }
