@import "../../styles/helpers";

.wrapperContainer {
    display: flex; }

.wrapperContainerImg {
    height: 100vh; }




.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 48px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 296px;
    @include m {
        max-width: 100%; } }

.logo {
    display: inline-block;
    width: 48px;
    margin-bottom: 32px;
    img {
        width: 100%; } }

.title {
    margin-bottom: 32px;
    font-size: 32px;
    width: 400px; }

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 100%;
        width: 100%;
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    .button {
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    margin-bottom: 10px;
    @include body2-s;
    color: $shades1; }

.info {
    margin-top: 32px;
    @include caption1;
    color: $n4;
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }

@media (max-width: 992px) {
  .wrapperContainerImg {
    display: none; } }
