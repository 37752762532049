@import "../../styles/helpers";

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 280px);
        width: calc(100% - 280px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 300px);
            width: calc(100% - 300px); }
        @include t {
            width: 100%;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 280px;
        position: sticky;
        top: 102px;
        @include x {
            top: 86px; }
        @include d {
            width: 300px; }
        @include t {
            display: none; } } }

.nav {
    display: flex;
    margin-left: auto;
    @include t {
        display: none; } }

.status {
    color: $n7; }

.link {
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    background: $n1;
    transition: all .2s;
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $s3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px; } }
