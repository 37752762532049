@import "../../../styles/helpers";

.description {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px;
            @include d {
                margin-bottom: 24px; } } } }

.editor {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.group {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 -6px -12px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 12px);
        width: calc(50% - 12px);
        margin: 0 6px 12px;
        @include m {
            width: 100%;
            margin: 12px 0 0; } } }

.field {
    margin-bottom: 32px; }


.switchContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 16px;
    margin-bottom: 34px;
    .switch {
        margin-bottom: 0px; }
    .label {
        @include base2;
        color: $n5;
        @include dark {
            color: $n3; } } }
