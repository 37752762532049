@import "../../styles/helpers";

.container {
    display: flex;
    background: #F4F4F4;
    border-radius: 12px;
    padding: 4px;
    padding-right: 0px;
    padding-bottom: 0px;
    border: 2px solid transparent;
    overflow: hidden;
    flex-wrap: wrap;
    @include dark {
        background: #1A1D1F;
        border: 2px solid #33373a; }
    .tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #2A85FF;
        border-radius: 8px;
        width: fit-content;
        padding: 8px 16px;
        margin-right: 10px;
        margin-bottom: 10px;
        .title {
            font-size: 13px;
            line-height: 24px;
            color: #FCFCFC;
            margin-right: 8px; }
        .icon {
            cursor: pointer;
            filter: invert(1); } } }
