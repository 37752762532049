@import "../../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 280px);
        width: calc(100% - 280px);
        padding-right: 8px;
        @include x {
            flex: 0 0 calc(100% - 312px);
            width: calc(100% - 312px); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 280px;
        @include x {
            width: 312px; }
        @include t {
            width: 100%; } } }

.spaceYinputs {
    > * + * {
        margin-top: 1rem; } }

.flex-board {
    display: flex;
    justify-content: space-between;
    align-items: start;
    > * + * {
        margin-left: 1rem; } }

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.inputsField {
    margin-top: 20px;
    overflow: auto;
    max-height: 300px;
    font-size: 14px;
    > * + * {
        margin-top: 1rem; } }

.outer {
    max-width: 800px;
    max-height: 80vh; }
