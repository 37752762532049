@import "../../styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; } }

.title {
    margin-right: 24px; }

.loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; }


.form {
    @include t {
        width: 300px; }
    @include m {
        width: 100%;
        margin-top: 16px;
        order: 3; } }

.sorting {
    display: flex;
    margin-left: auto; }

.link {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } }
    &.active {
        background: $n1;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        svg {
            fill: $n7; }
        @include dark {
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
            background: $n8;
            svg {
                fill: $n1; } } }
    &:not(:last-child) {
        margin-right: 12px; } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -12px 0;
    @include a {
        display: block;
        margin: 0 -8px; } }

.product {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 32px 12px 0;
    @include t {
        flex: 0 0 calc(50% - 24px);
        width: calc(50% - 24px); }
    @include a {
        width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px; }

.inlineFlex {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.inlineScroll {
    overflow-x: auto;
    width: calc(100% + 24px);
    margin-top: 30px;
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
        width: 14px;
        height: 18px; }
    &::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none; }
    &::-webkit-scrollbar-corner {
        background-color: transparent; } }

.deckWrapperWW {
    height: 200px;
    width: 200px;
    margin-right: 20px;
    border-radius: 8px;
    background: #eeeeee;
    display: inline-block;
    position: relative; }

.deckInfoWW {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 80%; }

.imgWrapperWW {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.imgObjectFit {
    object-fit: contain;
    width: 50%;
    height: 50%;
    opacity: 0.8;
    border: none; }

.deckPremiumWW {
    position: absolute;
    top: 20px;
    right: 20px; }

.deckConfigWW {
    position: absolute;
    top: 10px;
    right: 10px; }

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.control {
    display: none;
    align-items: center;
    @include t {
        display: none; }
    .button {
        margin-right: 8px; } }

.counter {
    margin-left: 16px;
    @include button2; }

.dropdown {
    min-width: 154px;
    margin-left: auto;
    @include m {
        width: 100%;
        margin-top: 16px; } }

.dropdownHead {
    @include m {
        @include dark {
            background: $n6;
            box-shadow: inset 0 0 0 2px $n6;
            color: $n1; } } }

.nav {
    display: flex;
    width: 100%;
    @include t {
        display: none; } }

.link {
    padding: 8px 16px;
    border-radius: 8px;
    width: auto;
    box-shadow: none!important;
    @include base1-s;
    color: $n4;
    transition: all .2s;
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.listCategories {}

.deckWrapper {
    border-top: 1px solid $n3;
    padding-top: 30px;
    padding-bottom: 30px; }


.foot {
    margin-top: 44px;
    text-align: center;
    @include m {
        margin-top: 32px; }
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }
