@import "../../styles/helpers";

.wrapper {
    padding-bottom: 24px;
    overflow: auto;
    background: $n1;
    border-radius: 8px;
    @include dark {
        background: $n7; } }

.table {
    display: table;
    width: 100%;
    height: 100%; }


.row {
    display: table-row;
    &:first-child {
        .col {
            padding-top: 16px;
            padding-bottom: 16px;
            @include caption2;
            font-weight: 500;
            color: $n4; } }
    &:nth-child(2n) {
        .col {
            background: rgba($n3, .35);
            @include dark {
                background: rgba($n6, .3); } } } }

.rowWrapper {
    max-height: 300px;
    overflow: auto; }

.col {
    display: table-cell;
    padding: 10px 16px;
    @include base2;
    &:first-child {
        padding-left: 24px;
        white-space: nowrap;
        color: $n4;
        @include m {
            padding-left: 16px; } }
    &:nth-child(2) {
        @include m {
            display: none; } }
    &:last-child {
        padding-right: 24px;
        @include m {
            padding-right: 16px; } } }

.status {
    font-size: 14px;
    line-height: 28px;
    font-weight: 600; }

.rowPersonalized {
    &:focus {
        background: #B1E5FC; } }

.flex {
    display: flex;
    align-items: center;
    > * + * {
        margin-left: 2rem; } }
