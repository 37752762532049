@import "../../../../styles/helpers";


.containerFlex {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem; }

.grid2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }

.col-span-2 {
    grid-column: span 2 / span 2;
    @media screen and (max-width: 480px) {
        grid-column: span 3 / span 3; } }

.separator {
    > * + * {
        margin-top: 1rem; }
    @media screen and (max-width: 480px) {
        grid-column: span 3 / span 3; } }

.card-board {
    > * + * {
        margin-top: 1rem; } }

.flex-board {
    display: flex;
    justify-content: space-between;
    align-items: start;
    > * + * {
        margin-left: 1rem; } }


.onMobile {
    display: none;
    @media screen and (max-width: 480px) {
        display: block;
        > * + * {
            margin-top: 1rem; } } }
