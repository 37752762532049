@import "../../../../styles/helpers";

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 280px);
        width: calc(100% - 280px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 300px);
            width: calc(100% - 300px); }
        @include t {
            width: 100%;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 280px;
        position: sticky;
        top: 102px;
        @include x {
            top: 86px; }
        @include d {
            width: 300px; }
        @include t {
            display: none; } } }

.nav {
    display: flex;
    margin-left: auto;
    @include t {
        display: none; } }

.status {
    color: $n7; }

.link {
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    background: $n1;
    transition: all .2s;
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $s3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.note {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    font-weight: 500;
    color: $n4;
    @include dark {
        border-color: $n6; } }

.list {
    margin-top: 24px; }

.item {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.date {
    padding: 24px 12px;
    @include d {
        width: 100%;
        text-align: center; } }

.foot {
    margin: 4px 8px 0;
    padding-top: 24px;
    border-top: 1px solid $n3;
    text-align: right;
    @include dark {
        border-color: $n6; }
    .button {
        &:not(:last-child) {
            margin-right: 8px; } } }

.time {
    width: 280px;
    padding: 24px;
    @include d {
        width: 100%; } }

.field {
    &.fieldIcon {
        .input {
            padding-left: 48px; } }
    &.fieldCurrency {
        .input {
            padding-left: 58px;
            background: none;
            border-color: $n3;
            &:focus {
                border-color: $shades1; }
            @include dark {
                border-color: $n6;
                &:focus {
                    border-color: $n5; } } } }
    &.fieldCopy {
        .input {
            padding-right: 48px; } }
    &.error {
        .icon {
            svg {
                fill: $p3; } }
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: rgba($s1, .25);
            border-color: transparent;
            color: $p3;
            @include dark {
                &:focus {
                    border-color: $p3;
                    color: $p3; } }
            &::placeholder {
                color: $p3; } } }
    &.success {
        .wrap {
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 48px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto; } }
        .input {
            padding-right: 48px;
            background: $n;
            border-color: $shades1;
            @include dark {
                background: $n8; } } } }

.label {
    margin-bottom: 14px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include inter;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; }
    @include placeholder {
        color: $n4;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }

.icon,
.copy {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px; }

.icon {
    left: 0;
    pointer-events: none;
    svg {
        fill: $n4; } }

.copy {
    right: 0;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.currency {
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    padding-top: 1px;
    border-radius: 10px 0 0 10px;
    background: $n2;
    color: $n7;
    @include dark {
        background: $n6;
        color: $n4; } }
