@import "../../styles/helpers";

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 280px);
        width: calc(100% - 280px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 300px);
            width: calc(100% - 300px); }
        @include t {
            width: 100%;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 280px;
        position: sticky;
        top: 102px;
        @include x {
            top: 86px; }
        @include d {
            width: 300px; }
        @include t {
            display: none; } } }
