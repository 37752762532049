@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 280px);
        width: calc(100% - 280px);
        padding-right: 8px;
        @include x {
            flex: 0 0 calc(100% - 312px);
            width: calc(100% - 312px); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 280px;
        @include x {
            width: 312px; }
        @include t {
            width: 100%; } }
    > * + * {
        margin-top: .5rem; } }

.outer {
    max-width: 900px; }

.spacey {
    > * + * {
        margin-top: 1rem; } }

.success {
    padding: 60px 0 24px;
    text-align: center;
    @include m {
        padding: 32px 0 16px; } }

.info {
    margin-right: auto;
    @include caption1;
    color: $n4;
    svg {
        margin-right: 12px;
        fill: $n4; }
    span {
        color: $n7;
        @include dark {
            color: $n1; } } }
