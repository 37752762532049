@import "../../../styles/helpers";

.line {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.info {
    margin-right: auto;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.switch {
    margin-left: 12px; }
