

.listSentence {
    > * + * {
        margin-top: 1em; } }

.charCard {
    margin-right: 0.5rem;
    background: white;
    border-top: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-bottom: 6px solid #E5E5E5;
    border-radius: 12px;
    cursor: pointer;
    padding: 6px 12px 6px 12px;
    &:hover {
        background: #F4F4F4; }
    &:active {
        background: #2A85FF29;
        border-top: 1px solid #2574FF;
        border-right: 1px solid #2574FF;
        border-left: 1px solid #2574FF;
        border-bottom: 6px solid #2574FF; }
    &:focus {
        background: #2A85FF29;
        border-top: 1px solid #2574FF;
        border-right: 1px solid #2574FF;
        border-left: 1px solid #2574FF;
        border-bottom: 6px solid #2574FF; } }
