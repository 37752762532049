@import "../../styles/helpers";

.card {
    position: relative;
    padding: 24px;
    background: $n1;
    border-radius: 8px;
    @include m {
        padding: 16px; }
    @include dark {
        background: $n7; } }

.head {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 32px;
    @include m {
        margin-bottom: 24px; } }

.title {
    margin-right: auto;
    > * + * {
        margin-left: 0.75rem; }
    @include m {
        line-height: 40px; } }

.textHelper {
    color: #A7B7C1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.02em; }

