@import "../../../../styles/helpers";

.field {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include d {
            margin-bottom: 24px; } } }

.inputsField {
    margin-top: 20px;
    > * + * {
        margin-top: 0.5rem; } }
