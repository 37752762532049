@import "../../styles/helpers";

.label {
    margin-bottom: 14px;
    @include base2;
    max-height: 500px;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    max-height: 500px;
    svg {
        fill: $shades1; } }

.dropdown {
    position: relative;
    z-index: 3;
    max-height: 500px!important;
    &.active {
        z-index: 10;
        max-height: 500px!important;
        .head {
            box-shadow: inset 0 0 0 2px $shades1;
            background: $n;
            max-height: 500px!important;
            @include dark {
                box-shadow: inset 0 0 0 2px $n5;
                background: $n8;
                color: $n1;
                max-height: 500px!important; }
            &:before {
                transform: rotate(180deg);
                max-height: 500px!important; } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            max-height: 500px;
            overflow-y: auto;
            .loaderContainer {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include title2; } } }
    &.small {
        .head {
            height: 40px;
            padding: 0 40px 0 16px;
            line-height: 40px;
            &:before {
                width: 40px;
                background-size: 16px auto; } }
        .option {
            padding: 4px 16px; } } }

.head {
    position: relative;
    height: 48px;
    padding: 0 48px 0 12px;
    max-height: 500px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px $n3;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    color: $n7;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    transition: all .2s;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 48px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto;
        transition: transform .2s; }
    &:hover {
        box-shadow: inset 0 0 0 2px $shades1; }
    @include dark {
        box-shadow: inset 0 0 0 2px $n6;
        background: none;
        color: $n4;
        &:hover {
            box-shadow: inset 0 0 0 2px $n5; } } }

.selection {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.body {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    max-height: 500px;
    right: 0;
    padding: 8px 0;
    border-radius: 12px;
    background: $n;
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $n8;
        box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgba($n8, .1); }
    &.bodyUp {
        top: auto;
        bottom: calc(100% + 2px); } }

.option {
    padding: 8px 12px;
    @include title2;
    color: $n4;
    max-height: 500px;
    transition: color .2s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    .character {
        font-size: 30px;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n4; } }


    &:hover {
        color: $n7;
        @include dark {
            color: $n1; }
        .character {
            color: $p1;
            @include dark {
                color: $p1; } } }
    &.selectioned {
        color: $p1;
        @include dark {
            color: $p1; } } }


.inputSearch {
    background: transparent;
    border: 0;
    max-height: 500px;
    outline: none;
    color: $n7;
    width: 100%;
    @include dark {
        color: $n1; } }
